// consumer_apply_getApprovalProgress 根据出差单号获取审批信息
const __request = require(`./__request/__request_contentType_json`);
  const consumer_apply_getApprovalProgress = (pParameter) => {
    if (!pParameter) pParameter = {};

    let datas = {};
    datas.evectionNo =  pParameter.evectionNo ? pParameter.evectionNo : '';

    const p = {
        method: 'post',
        urlSuffix: '/consumer/apply/getApprovalProgress',
        data: datas
    };

    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_apply_getApprovalProgress;
