//
// approveType： 1、出差单，2、超标单，3、报销单，4、改签单，5、退订单，
// type：1、酒店退订，2、机票退订，3、火车票退订，4、机票改签，5、火车票改签，
// fromType：1、我的申请详情，2、我的参与详情，3、已审批详情，4、未审批详情，5、我的出差详情，6、我的预定详情，7、企业待支付详情，8、企业已支付详情，

import moment from "moment";

//
import journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import WorkflowProcessLineForArray from "@/component/workflow-process-line-for-array/index.vue";
import EvectionList from "../../component/evectionList/index.js";


// 根据出差单号获取详情
import consumer_evection_evectionDC from "@/lib/data-service/haolv-default/consumer_evection_evectionDC";
// 根据出差单号获取审批信息
import consumer_apply_getApprovalProgress from "@/lib/data-service/haolv-default/consumer_apply_getApprovalProgress";
// 格式化流程显示
import {formatStartNodeShow} from "@/lib/data-service/haolv-default/design";

export default {
    components: {WorkflowProcessLineForArray,EvectionList},
    data() {
        return {
            loading: false,
            fromType: "",
            detail: {},
            moment: moment,
            workflowList: [],
            //  审批进度信息
            recordList: [],
        };
    },
    methods: {
        evectionList(list) {
            let box = [{
                evectionNo: '',
                travelStatusText: '',
                journeyList: [],
            }];
            if (list) {
                list.forEach((item,index) => {
                    box[0].evectionNo = this.detail.evectionNo;
                    box[0].travelStatusText = this.detail.travelStatusText;
                    box[0].journeyList.push({
                        businessType: item.businessType,
                        carPolicyType: item.carPolicyType,
                        id: item.id,
                        cityName: item.cityName,
                        carPolicyTypeText: item.carPolicyTypeText,
                        startTime: item.departTime,
                        bookingButton: item.bookingButton,
                    });
                });
            }

            return box;
        },
        async init() {
            this.loading = true;
            const query = this.$route.query;
            this.fromType = Number(query.fromType);
            const fromType = this.fromType;

            const applyOrder = [1, 2, 3, 4];
            const evectionOrder = [5, 6, 7, 8];
            if (applyOrder.includes(fromType)) {
                console.log("applyOrder");
            }
            if (evectionOrder.includes(fromType)) {
                // , pageType: 2, personType: 2
                // consumer_evection_evectionDC({
                //   evectionNo: query.evectionNo,
                // }).then(res => {
                //     console.log(res);
                // });
                let [err, res] = await awaitWrap(consumer_evection_evectionDC({
                    evectionNo: query.evectionNo,
                }));
                if (err) {
                    this.loading = false;
                    return
                }
                const detail = res.datas;
                this.detail = detail;
                this.loading = false;
                console.log("evectionOrder");

                if (this.$store.state.workflow === '2') {
                    consumer_apply_getApprovalProgress({evectionNo: this.$route.query.evectionNo}).then(res => {
                        this.recordList = res.datas ? res.datas : [];
                    });
                }
                if (this.$store.state.workflow === '1') {
                    formatStartNodeShow({
                        deptId: this.$store.state.userInfo.deptId,
                        processInstanceId: detail.applyNo,
                        userId: this.$store.state.userInfo.id,
                        budgetAmount: detail.budgetAmount || 0,
                    }).then(res=>{
                        let list = res.result;
                        let form = {
                            line: list
                        }
                        // this.workflowList = list;
                        // console.log(this.workflowList);
                        // this.workflowList = list;
                        // console.log(this.$refs.workflowProcessLineForArray);
                        this.$refs.workflowProcessLineForArray.init(form);
                    })
                }
            }
        },
        format_status(val) {
            switch (Number(val)) {
                case 0:
                    return {text: "未开始", class: "notstart"};
                    break;
                case 1:
                    return {text: "待审核...", class: "pending"};
                    break;
                case 2:
                    return {text: "审核通过", class: "success"};
                    break;
                case 3:
                    return {text: "已拒绝", class: "fail"};
                    break;
                case 4:
                    return {text: "已失效", class: "failure"};
                    break;
            }
        },

        downonload_accessoryUrl(url) {
            window.open(url);
        },

        to_apply_detail(approveType, id) {
            let query = {
                applyId: id,
                type: "info",
                approveType,
                lastMenu: "adminMyApply",
            };
            // if (approveType === 1) {

            // } else if (approveType === 2) {
            //   query = {applyId: id,type: 'info',approveType,lastMenu: 'adminMyApply'};
            // } else if (approveType === 3) {

            // } else if (approveType === 4) {

            // } else if (approveType === 5) {

            // }

            this.$router.push({
                name: "admin-my-apply-manage-apply-info",
                query,
            });

            // this.$router.push({
            //   name: "admin-apply-detail",
            //   query: {
            //     fromType: 1,
            //     approveType,
            //     id,
            //   },
            // });
        },

        to_flight_detail(orderNo) {
            this.$router.push({
                name: "admin-my-order-flight-detail",
                query: {
                    orderNo,
                },
            });
        },

        to_train_detail(orderNo) {
            this.$router.push({
                name: "admin-my-order-train-detail",
                query: {
                    orderNo,
                },
            });
        },

        to_hotel_detail(orderNo) {
            this.$router.push({
                name: "admin-hotel-order-detail",
                query: {
                    orderNo,
                },
            });
        },

        back() {
            this.$router.back();
        },

        goReservePage() {
            this.$router.push({
                name: 'admin-travel-reserve',
                query: {
                    evectionNo: this.$route.query.evectionNo,
                    evectionType: this.detail.evectionType,
                    redirectType: '001'
                }
            });
        }
    },
    beforeRouteEnter(to, form, next) {
        console.log(to);
        switch (Number(to.query.fromType)) {
            case 1:
                to.meta.lastMenu = "adminMyApply";
                break;
            case 2:
                to.meta.lastMenu = "adminMyApplyParticipation";
                break;
            case 3:
                to.meta.lastMenu = "adminHaveApprove";
                break;
            case 4:
                to.meta.lastMenu = "adminNoApprove";
                break;
            case 5:
                to.meta.lastMenu = "adminMyEvection";
                break;
            case 6:
                to.meta.lastMenu = "adminMyReserve";
                break;
            case 7:
                to.meta.lastMenu = "enterprisesNoPay";
                break;
            case 8:
                to.meta.lastMenu = "enterprisesPay";
                break;
        }
        next();
    },
    async activated() {
        await this.$store.state.workflowDefer.promise;
        await this.$store.state.userInfoDefer.promise;
        this.init();
    },
    filters: {
        carPolicyTypeText(val) {
            let valText = '';
            switch (val) {
                case 1:
                    valText = '出差用车';
                    break;
                case 2:
                    valText = '加班用车';
                    break;
                case 3:
                    valText = '接送服务';
                    break;
                case 4:
                    valText = '代叫用车';
                    break;
                case 5:
                    valText = '定点通勤';
                    break;
                case 6:
                    valText = '外勤用车';
                    break;
            }
            return valText
        },
        format_evectionType(val) {
            switch (Number(val)) {
                case 1:
                    return "个人创建";
                    break;
                case 2:
                    return "出差单创建";
                    break;
            }
        },
        format_week(val) {
            switch (moment(val).day()) {
                case 0:
                    return "周日";
                    break;
                case 1:
                    return "周一";
                    break;
                case 2:
                    return "周二";
                    break;
                case 3:
                    return "周三";
                    break;
                case 4:
                    return "周四";
                    break;
                case 5:
                    return "周五";
                    break;
                case 6:
                    return "周六";
                    break;
            }
        },
        format_duration(val) {
            return val.replace("h", "小时").replace("m", "分钟");
        },
        format_label(val) {
            switch (Number(val)) {
                case 1:
                    return "出差申请";
                    break;
                case 2:
                    return "出差政策";
                    break;
                case 3:
                    return "报销申请";
                    break;
                case 4:
                    return "改签申请";
                    break;
                case 5:
                    return "退票申请";
                    break;
            }
        },
        format_approveType(val) {
            switch (Number(val)) {
                case 1:
                    return "出差申请单";
                    break;
                case 2:
                    return "超标申请单";
                    break;
                case 3:
                    return "报销申请单";
                    break;
                case 4:
                    return "改签申请单";
                    break;
                case 5:
                    return "退票申请单";
                    break;
            }
        },
    },
};
